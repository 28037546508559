import React, { useState } from 'react';
import { Link, withRouter } from "react-router-dom";
import './index.css';

function Header(props: any) {
  console.log(props.location.pathname);
  const [isShowGzhPic, changeShowPic] = useState(false);


  return (
    <header >
      <div className="header">
        <a href="https://weibo.com/majlegend?topnav=1&wvr=6&topsug=1" target="_blank"><img src="/header/weibo.png" alt="" /></a>
        <a href="#" onMouseOver={() => changeShowPic(true)} onMouseOut={() => changeShowPic(false)}><img src="/header/weixin.png" alt="" /></a>

        <img className={isShowGzhPic ? 'gzhShow' : 'gzh'} src="/header/gzh.jpg" alt="" />
      </div>
    </header >
  );
}

export default withRouter(Header);
