import React from 'react';
import { Link } from "react-router-dom";
import './index.css';

function Footer() {
    return (
        <div className="footer">
            <p className="tip">
                抵制不良游戏，拒绝盗版游戏。注意自我保护，谨防上当受骗。适度游戏益脑，沉迷游戏伤身。合理安排时间，享受健康生活。
            </p>
            <p>
                Copyright © 2018 上海寻光长青网络科技有限公司 ALL Rights Reserved <a href="https://beian.miit.gov.cn/">沪ICP备2022028194号</a> 
            </p>
            <p>
                沪新出科数【2018】113号；新广出审【2018】1110号；ISBN：978-7-498-04507-2；软著登记号：2017SR703153
            </p>
            <p>
                著作权人：上海佰游网络科技有限公司；出版单位：上海同济大学电子音像出版社；运营单位：深圳市豆悅网络科技有限公司
            </p>
            
        </div>
    );
}

export default Footer;
