import React from 'react';

import './index.css';


function Report() {
  return (
    <div className="report">
      <h2>纠纷处理</h2>
      <p>
        上海寻光长青网络科技有限公司在此特别提醒用户：在注册、登录本游戏前请务必仔细阅读本《纠纷处理方式》（未成年人应当在其法定监护人陪同下阅读）中的 各个条款，以确保您在享受本网络游戏服务的同时维护自身合法权益：用户之间纠纷处理方式
        <br/>
        <br/>
        1.如若本游戏用户之间发生纠纷且无法协商一致的，可向国家相关行政机关或司法机关寻求解决，包括但不限于向公安机关报案、申请仲裁 或向人民法院提起诉讼等。上海寻光长青网络科技有限公司将会为实名注册用户提供必要的协助和支持，并根据有关行政机关和司法机关的要求承担一定的举证责任或 采取必要措施。
        <br/>
        <br/>
        2.本游戏用户之间发生纠纷的，也可向上海寻光长青网络科技有限公司的客服人员投诉并举证。用户需提供与其账号注册信息一致的个人有效身份证件、必要的国 家行政或司法机关文件和其他上海寻光长青网络科技有限公司要求提供的相关证据。经上海寻光长青网络科技有限公司审核确认后，可以给予必要的协助和支持。用户与上海寻光长青网络科技有限公司之间纠纷处理方式
        <br/>
        <br/>
        如游戏用户对上海寻光长青网络科技有限公司的服务有任何异议的，可以向上海寻光长青网络科技有限公司的客服人员投诉并举证。查证属实的，上海寻光长青网络科技有限公司将立即更正并按照现行法律规 定给予用户必要的补偿。
        <br/>
        <br/>
        交易争议处理
        <br/>
        <br/>
        【交易争议处理途径】您在上海寻光长青网络科技有限公司平台交易过程中与其他用户发生争议的，您或其他用户中任何一方均有权选择以下途径解决：
        <br/>
        <br/>
        （一）与争议相对方自主协商；
        <br/>
        <br/>
        （二）使用上海寻光长青网络科技有限公司平台提供的争议调处服务；
        <br/>
        <br/>
        （三）请求消费者协会或者其他依法成立的调解组织调解；
        <br/>
        <br/>
        （四）向有关行政部门投诉；
        <br/>
        <br/>
        （五）根据与争议相对方达成的仲裁协议（如有）提请仲裁机构仲裁；
        <br/>
        <br/>
        （六）向人民法院提起诉讼。
        <br/>
        <br/>
        【平台调处服务】如您依据上海寻光长青网络科技有限公司平台规则使用上海寻光长青网络科技有限公司平台的争议调处服务，则表示您认可并愿意履行上海寻光长青网络科技有限公司平台的客服或大众评审员（“调处方”）作为独立的第三方根据其所了解到的争议事实并依据上海寻光长青网络科技有限公司平台规则所作出的调处决定（包括调整相关订单的交易状态、判定将争议款项的全部或部分支付给交易一方或双方等）。在上海寻光长青网络科技有限公司平台调处决定作出前，您可选择上述（三）、（四）、（五）、（六）途径（下称“其他争议处理途径”）解决争议以中止上海寻光长青网络科技有限公司平台的争议调处服务。 如您对调处决定不满意，您仍有权采取其他争议处理途径解决争议，但通过其他争议处理途径未取得终局决定前，您仍应先履行调处决定。

      </p>
    </div>
  );
}

export default Report;
