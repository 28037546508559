import React from 'react';
import { Link } from "react-router-dom";

function Main() {
  return (
    <div className="main">
      <div className="top">
        <h2>家长监护</h2>
        <p>
          “网络游戏未成年人家长监护工程”是一项由国内网络游戏企业共同发起并参与实施，由中华人民共和国文化部指导，旨在加强家长对未成年人参与网络游戏的监护，引导未成年人健康、绿色参与网络游戏，和谐家庭关系的社会性公益行动。它提供了一种切实可行的方法，一种家长实施监控的管道，使家长纠正部分未成年子女沉迷游戏的行为成为可能。
         </p>
        <p>
          该项社会公益行动充分反映了中国网络游戏行业高度的社会责任感，对未成年玩家合法权益的关注以及对用实际行动营造和谐社会的愿望。
         </p>
      </div>
      <div className="left">
        <img className="explain_pic" src="/monitor/explain.png" alt="explain" />
        <img className="progress_pic" src="/monitor/progress.png" alt="progress" />
      </div>
      <div className="middle">
        <h3>家长监护服务说明</h3>
        <p>
          <Link to="/monitor/explain">
            家长监护系统充分考虑家长的实际需求，当家长发现自己的孩子玩游戏过于沉迷的时候，由家长提供合法的监护人资质证明、游戏名称账号、以及家长对于限制强度的愿望等信息，可对处于孩子游戏沉迷状态的账号采取几种限制措施，解决未成年人沉迷网游的不良现象，如限制孩子每天玩游戏的时间区间和长度，也可以限制只有周末才可以游戏，或者完全禁止。
            <span className="enter">进入</span>
          </Link>
        </p>
        <h3>家长监护服务进度查询</h3>
        <p>
          <Link to="/monitor/process">
            如果您已经申请家长监护服务，您可以通过给服务邮箱发邮件进行进度查询，了解您所提交的服务申请最新处理进展。服务期间，如果您对需要提交的信息或者处理结果有疑问，或者其他任何问题，您均可以随时联系我们，我们将由专门负责的客服主管为您提供咨询解答服务，或者配合、指导您解决问题。
            <span className="enter">进入</span>
          </Link>
        </p>
      </div>
      <div className="right">
        <Link to="/monitor/progress"><h1>申请服务流程</h1></Link>
        <a href="/monitor/shenqing.zip" className="download">监护服务申请文档</a>
        <a href="/monitor/jiechu.zip" className="download">账号疑义申诉文档</a>
        <div className="tel">
          <img className="icon_tel" src="/monitor/icon_tel.png" alt="icon_tel" />
          <p>
            服务联系方式：
            <br />
            服务电话：021-80158703
          </p>
        </div>
        <div className="wall">
          <h3>家长监护服务FAQ</h3>
          <p>
            <Link to="/monitor/faq">1、我可以通过哪些渠道与你们公司取得联系？</Link>
            <br/>
            <Link to="/monitor/faq">2、你们这项服务收费吗？</Link>
            <br/>
            <Link to="/monitor/faq">3、你们的服务过程大概是什么样的？</Link>
            <br/>
            <Link to="/monitor/faq">4、针对未成年人家长监护系统的受理时间是什么</Link>
            <br/>
            <Link to="/monitor/faq">5、我发送的信息通过什么方式提供给你们？</Link>
          </p>
        </div>
      </div>
      <div className="bottom">
        <h3>家长监护系统用户啊使用流程</h3>
        <img className="process_pic" src="/monitor/process.png" alt="process"/>
      </div>
    </div>
  );
}

export default Main;
